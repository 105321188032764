import styled from "styled-components"
import { SectionHeading, Commonh6, Commonpara } from "../Common/common.style"
import { device } from "../Common/device"
import { SocialFacebook } from "@styled-icons/typicons/SocialFacebook"
import { SocialTwitter } from "@styled-icons/typicons/SocialTwitter"
import { SocialLinkedin } from "@styled-icons/typicons/SocialLinkedin"
import { SocialInstagram } from "@styled-icons/typicons/SocialInstagram"

export const TeamSection = styled.section`
  background: #000;
  padding: 100px 0px;

  @media ${device.tablet} {
    padding: 80px 10px;
  }
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 50px;

  @media ${device.tablet} {
    margin-bottom: 35px;
  }
`

export const HeadingSpan = styled.span`
  color: #c5a47e;
`

export const TeamSlider = styled.div`
  max-width: 250px;
`

export const TeamSliderWrapper = styled.div`
  outline: 0;
`

export const TeamSliderLayout = styled.div`
  margin: 20px 15px;
  position: relative;
`

export const ImageLayout = styled.div``

export const TeamImgFigure = styled.figure`
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  width: 100%;

  :hover .teamImg {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  .teamImg {
    height: 250px;
    backface-visibility: hidden;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: transform 0.3s ease-in-out !important;
    -webkit-transition: -webkit-transform 0.3s ease-in-out !important;
  }
`
export const TeamDesignation = styled(Commonh6)`
  position: absolute;
  text-align: center;
  background: #c5a47e;
  line-height: 1;
  padding: 10px 15px;
  margin-bottom: 0;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  top: 48%;
  left: -10px;

  @media ${device.tablet} {
    line-height: 1;
  }
`

export const TeamDesc = styled.div`
  text-align: center;
  background: #151515;
  position: relative;
  padding: 20px 10px 15px;
`

export const TeamName = styled(Commonh6)`
  margin-bottom: 10px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TeamPhoneAnchor = styled.a`
  :hover {
    color: #ef5350;
  }
`

export const TeamPhone = styled(Commonpara)`
  text-align: center;
  margin-bottom: 0px;
`

export const SocialLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FbIcon = styled(SocialFacebook)`
  width: 32px;
  color: #fff;
  padding: 5px;
  border-radius: 100%;
  margin: 0px 5px;
  transition: all 0.5s;

  :hover {
    transform: rotate(30deg);
    transition: all 0.5s;
  }
`

export const TwitterIcon = styled(SocialTwitter)`
  width: 32px;
  color: #fff;
  padding: 5px;
  border-radius: 100%;
  margin: 0px 5px;
  transition: all 0.5s;

  :hover {
    transform: rotate(30deg);
    transition: all 0.5s;
  }
`

export const LinkedinLink = styled.a``

export const LinkedinIcon = styled(SocialLinkedin)`
  width: 32px;
  color: #fff;
  padding: 5px;
  border-radius: 100%;
  margin: 0px 5px;
  transition: all 0.5s;

  :hover {
    transform: rotate(30deg);
    transition: all 0.5s;
  }
`

export const InstaIcon = styled(SocialInstagram)`
  width: 32px;
  color: #fff;
  padding: 5px;
  border-radius: 100%;
  margin: 0px 5px;
  transition: all 0.5s;

  :hover {
    transform: rotate(30deg);
    transition: all 0.5s;
  }
`

export const NextPrev = styled.div`
  text-align: center;
`

export const ImgButton = styled.button`
  border: none;
  background: none;
  outline: 0;

  :focus {
    outline: 0;
  }
`

export const PrevImg = styled.img`
  margin: 20px 0px 0px;
  width: 40px;
  cursor: pointer;
  transition: all 0.4s;
  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }
`

export const NextImg = styled.img`
  margin: 20px 0px 0px;
  width: 40px;
  cursor: pointer;
  transition: all 0.4s;
  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }
`
