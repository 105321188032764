import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #c5a47e;
    color: #fff;
    padding: 8px 40px;
    text-decoration:none;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#9e7647;
        color: #fff;
        text-decoration:none;
    }

    @media ${device.mobileXL} {
        padding: 8px 30px;
    }
`;

export const Commonh1 = styled.h1`
    font-size:60px;
    line-height:1.25;
    margin-bottom:20px;
    text-transform:uppercase;
    color:#fff;

    @media ${device.laptop} {
        font-size:50px;
        line-height:56px;
    }

    @media ${device.mobileXL} {
        font-size:40px;
        line-height:46px;
    }
`;

export const Commonh2 = styled.h2`
    font-size: 42px;
    line-height:48px;
    margin-bottom: 20px;
    text-transform:uppercase;
    color:#fff;

    @media ${device.tablet} {
        font-size:40px;
        line-height:46px;
    }
`;

export const Commonh3 = styled.h3`
    font-size: 36px;
    line-height:42px;
    color:#fff;
    margin-bottom: 20px;
    text-transform:uppercase;
`;

export const Commonh4 = styled.h4`
    font-size:30px;
    line-height:36px;
    color:#fff;
    margin-bottom:20px;
    text-transform:uppercase;

    @media ${device.tablet} {
        font-size:28px;
        line-height:32px;
    }
`;

export const Commonh5 = styled.h5`
    font-size:24px;
    line-height:30px;
    color:#fff;
    margin-bottom:20px;
    text-transform:uppercase;
`;

export const Commonh6 = styled.h6`
    font-size:20px;
    line-height:24px;
    color:#fff;
    margin-bottom:20px;

    @media ${device.mobileXL} {
        font-size:18px;
        line-height:24px;
    }
`;

export const Commonpara = styled.p`
    color:#fff;
    margin-bottom:20px;
    font-size:14px;
    line-height:1.5;
`;

export const SectionHeading = styled(Commonh2)`
    text-align:center;
    line-height:1;
    text-transform:uppercase;

    @media ${device.tablet} {
        line-height:1;
    }
`;
