import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  LanguageMenuWrapper,
  LanguageMenuBtn,
  LanguageMenuSeparator,
} from "./languageMenu.style"
const LanguageMenu = props => {
  const { i18n } = useTranslation()
  const [values, setValues] = useState({
    language: i18n.language,
  })

  function handleChange(lang) {
    i18n.changeLanguage(lang)

    setValues(oldValues => ({
      ...oldValues,
      language: lang,
    }))
  }

  return (
    <LanguageMenuWrapper>
      <LanguageMenuBtn
        onClick={() => handleChange("en")}
        issticky={props.issticky}
        isActive={values.language === "en"}
      >
        En
      </LanguageMenuBtn>
      <LanguageMenuSeparator issticky={props.issticky}>
        {" "}
        •{" "}
      </LanguageMenuSeparator>
      <LanguageMenuBtn
        onClick={() => handleChange("fr")}
        issticky={props.issticky}
        isActive={values.language === "fr"}
      >
        Fr
      </LanguageMenuBtn>
    </LanguageMenuWrapper>
  )
}

export default LanguageMenu
