import React, { Component } from "react"
import {
  FooterSection,
  Heading,
  HeadingSpan,
  FooterWrapper,
  ContactWrapper,
  ContactInfoLayout,
  ContactUsLayout,
  DetailsLayout,
  DetailsIcon,
  DetailsText,
  DetailsHeading,
  BottomFooterWrapper,
  BottomFooterPara,
  BottomLink,
  CustomRow,
  DetailsIconWrapper,
} from "./footer.style"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticQuery, graphql } from "gatsby"

import Team from "../Team"

import { withTrans } from "../../../pagedata/i18n/withTrans"

class Footer extends Component {
  render() {
    const FooterData =
      this.props.language === "en"
        ? this.props.FooterData.en.translation.Footer
        : this.props.FooterData.fr.translation.Footer
    return (
      <FooterSection id="footerSection">
        <FooterWrapper>
          <Container>
            <Heading>
              {FooterData.Heading}
              <HeadingSpan>{FooterData.HeadingSpan}</HeadingSpan>
            </Heading>

            <ContactWrapper>
              <ContactInfoLayout>
                <ContactUsLayout>
                  <Row>
                    <Col
                      sm={5}
                      md={5}
                      lg={5}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Team />
                    </Col>
                    <Col
                      sm={6}
                      md={6}
                      lg={6}
                      className="d-flex flex-column justify-content-center"
                    >
                      <Row className="align-items-center m-0">
                        {FooterData.DetailsData.slice(0, 3).map(
                          (details, i) => (
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              key={"details_" + i}
                            >
                              <DetailsLayout
                                href={details.DetailsLink}
                                target="_blank"
                                rel="noreferrer noopener"
                                title={details.LinkTitle}
                              >
                                <DetailsIconWrapper>
                                  <DetailsIcon
                                    src={details.DetailsIcon}
                                    alt=""
                                  />
                                </DetailsIconWrapper>
                                <DetailsHeading>
                                  {details.DetailsHeading}
                                </DetailsHeading>
                                <DetailsText>{details.DetailsText}</DetailsText>
                              </DetailsLayout>
                            </Col>
                          )
                        )}
                      </Row>
                      <Row className="align-items-center m-0">
                        {FooterData.DetailsData.slice(3).map((details, i) => (
                          <Col xs={4} sm={4} md={4} lg={4} key={"details" + i}>
                            <DetailsLayout
                              href={details.DetailsLink}
                              target="_blank"
                              rel="noreferrer noopener"
                              title={details.LinkTitle}
                            >
                              <DetailsIconWrapper>
                                <DetailsIcon src={details.DetailsIcon} alt="" />
                              </DetailsIconWrapper>
                              <DetailsHeading>
                                {details.DetailsHeading}
                              </DetailsHeading>
                            </DetailsLayout>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </ContactUsLayout>
              </ContactInfoLayout>
            </ContactWrapper>
          </Container>
        </FooterWrapper>
        <BottomFooterWrapper>
          <Container>
            <CustomRow>
              <Col md="12" className="m-auto">
                <BottomFooterPara>
                  {FooterData.BottomFooterPara}
                  <BottomLink
                    rel="noreferrer"
                    href={FooterData.BottomFooterLink}
                    target="_blank"
                  >
                    {FooterData.BottomFooterName}{" "}
                  </BottomLink>
                  <BottomLink
                    rel="noreferrer"
                    href={FooterData.BottomFooterMentionsLink}
                    target="_blank"
                  >
                    - {FooterData.BottomFooterMentions}
                  </BottomLink>
                </BottomFooterPara>
              </Col>
            </CustomRow>
          </Container>
        </BottomFooterWrapper>
      </FooterSection>
    )
  }
}
const FooterComponent = props => (
  <StaticQuery
    query={graphql`
      query {
        localesJson {
          en {
            translation {
              Footer {
                Heading
                HeadingSpan
                ContactButtonText
                DetailsData {
                  DetailsIcon
                  DetailsHeading
                  DetailsLink
                  LinkTitle
                  DetailsText
                }
                BottomFooterSocial {
                  label
                  url
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
                BottomFooterMentions
                BottomFooterMentionsLink
              }
            }
          }
          fr {
            translation {
              Footer {
                Heading
                HeadingSpan
                ContactButtonText
                DetailsData {
                  DetailsIcon
                  DetailsHeading
                  DetailsLink
                  LinkTitle
                  DetailsText
                }
                BottomFooterSocial {
                  label
                  url
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
                BottomFooterMentions
                BottomFooterMentionsLink
              }
            }
          }
        }
      }
    `}
    render={data => <Footer FooterData={data.localesJson} {...props} />}
  />
)

export default withTrans(FooterComponent)
