import React, { Component } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  TeamSlider,
  TeamSliderWrapper,
  TeamSliderLayout,
  ImageLayout,
  TeamImgFigure,
  TeamDesignation,
  TeamDesc,
  TeamName,
  LinkedinIcon,
  LinkedinLink,
} from "./team.style"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { StaticQuery, graphql } from "gatsby"
import GatsImg from "gatsby-image"

import { withTrans } from "../../../pagedata/i18n/withTrans"

class TeamPage extends Component {
  render() {
    const TeamData =
      this.props.language === "en"
        ? this.props.TeamData.en.translation.Team
        : this.props.TeamData.fr.translation.Team

    return (
      <TeamSlider>
        <TeamSliderWrapper>
          <TeamSliderLayout>
            <ImageLayout>
              <TeamImgFigure>
                <GatsImg
                  fluid={
                    TeamData.TeamSlider[0].TeamSliderImg.childImageSharp.fluid
                  }
                  alt=""
                  className="teamImg"
                />
              </TeamImgFigure>
            </ImageLayout>
            <TeamDesignation>
              {TeamData.TeamSlider[0].TeamDesignation}
            </TeamDesignation>
            <TeamDesc>
              <TeamName>
                {TeamData.TeamSlider[0].TeamName}{" "}
                <LinkedinLink
                  href="https://www.linkedin.com/in/adrien-rognon-60470a4b/"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="Adrien Rognon linkedin"
                >
                  <LinkedinIcon />
                </LinkedinLink>
              </TeamName>
            </TeamDesc>
          </TeamSliderLayout>
        </TeamSliderWrapper>
      </TeamSlider>
    )
  }
}

const Team = props => (
  <StaticQuery
    query={graphql`
      query {
        localesJson {
          en {
            translation {
              Team {
                Heading
                HeadingSpan
                TeamSlider {
                  TeamSliderImg {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  TeamName
                  TeamDesignation
                }
                PrevImg
                NextImg
              }
            }
          }
          fr {
            translation {
              Team {
                Heading
                HeadingSpan
                TeamSlider {
                  TeamSliderImg {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  TeamName
                  TeamDesignation
                }
                PrevImg
                NextImg
              }
            }
          }
        }
      }
    `}
    render={data => <TeamPage TeamData={data.localesJson} {...props} />}
  />
)
export default withTrans(Team)
