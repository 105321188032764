import styled from "styled-components"
import { Commonh1, Commonpara } from "../Common/common.style"
import { device } from "../Common/device"

import BrandImg from "../../../images/logo-wc-heading.png"

export const BannerSection = styled.section`
  min-height: 100vh;
`

export const BannerWrapper = styled.div`
  .slick-list {
    line-height: 0;
  }

  .slick-active {
    z-index: 999;
  }

  .slick-dots {
    height: 100%;
    display: flex;
    align-items: center;
    bottom: 0;
    width: auto;
    position: absolute;
    left: 0;
    margin: 0px;
    padding-right: 15px;

    @media ${device.laptopL} {
      margin-left: calc(50% - 570px);
    }

    @media ${device.laptopM} {
      margin-left: calc(50% - 480px);
    }

    @media ${device.laptop} {
      margin-left: calc(50% - 360px);
    }

    @media ${device.tablet} {
      height: auto;
      display: block;
      left: 0;
      right: 0;
      bottom: 80px;
      margin-left: 0;
      padding-left: 0px;
    }
  }

  ul {
    margin: 0px;
  }

  li {
    display: block !important;
    margin: 2px 0px;

    @media ${device.tablet} {
      display: inline-block !important;
      margin: 5px 5px;
      height: auto;
      width: auto;
    }
  }

  .slick-dots span {
    width: 20px;
    height: 3px;
    border-radius: 2px;
    display: block;
    background: #fff;
  }

  .slick-active span {
    width: 35px !important;
    height: 3px !important;
    background: #c5a47e;
  }
`

export const BannerSlide = styled.div`
  position: relative;
`

export const BannerImageHolder = styled.div`
  position: relative;
  min-height: 100vh;
  margin-left: auto;

  .bannerImage {
    height: 100vh;
  }

  .bannerImage:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`

export const BannerContents = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
`

export const Brand = styled.div`
  filter: contrast(0.5);
  height: 22rem;
  width: 16rem;
  background-image: url(${BrandImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

export const BannerLayout = styled.div`
  max-width: 55%;
  margin-left: auto;

  @media ${device.laptopM} {
    max-width: 65%;
  }

  @media ${device.tablet} {
    max-width: 100%;
    padding: 0px 10px;
  }
`

export const BannerHeading = styled(Commonh1)`
  color: #fff;
  text-align: right;

  @media ${device.tablet} {
    text-align: center;
  }
`

export const BannerSubHeading = styled(Commonpara)`
  color: #fff;
  text-align: right;
  font-size: 16px;
  line-height: 24px;
  margin-left: 50px;

  @media ${device.tablet} {
    text-align: center;
    margin-left: 0px;
  }
`

export const NextPrev = styled.div`
  text-align: center;
  position: absolute;
  bottom: 40px;
  right: 50px;
`

export const ImgButton = styled.button`
  line-height: 0px;
  padding-bottom: 0px;
  border: none;
  background: none;
  outline: none;

  :focus {
    outline: 0;
  }
`

export const PrevImg = styled.img`
  margin: 0px 5px;
  cursor: pointer;
  height: 40px;
  transition: all 0.4s;
  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }

  @media ${device.tablet} {
    height: 20px;
  }
`

export const NextImg = styled.img`
  margin: 0px 5px;
  cursor: pointer;
  transition: all 0.4s;
  height: 40px;
  :hover {
    transform: scale(1.1);
    transition: all 0.4s;
  }
  @media ${device.tablet} {
    height: 20px;
  }
`
