import styled from "styled-components"
import { SectionHeading, Commonpara, Commonh6 } from "../Common/common.style"
import { device } from "../Common/device"
import { TabList } from "react-tabs"
import { ChevronLeft } from "@styled-icons/boxicons-regular/ChevronLeft"
import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight"
import { Row } from "react-bootstrap"
import BackgroundImg from "../../../assets/realestate-3-images/bg-pattern.png"

export const PropertiesSection = styled.section`
  padding: 100px 0px;
  background: #000;
  position: relative;

  @media ${device.tablet} {
    padding: 80px 10px;
  }

  @media ${device.mobileXL} {
    padding: 0px 10px;
  }

  .slick-list {
    line-height: 0;
  }
`

export const CustomRow = styled(Row)`
  align-items: center;
`

export const Heading = styled(SectionHeading)`
  text-align: left;
`

export const HeadingSpan = styled.span`
  color: #c5a47e;
`

export const Description = styled(Commonpara)``

export const CustomTabList = styled(TabList)`
  margin: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #555;

  .react-tabs__tab {
    font-size: 14px;
    margin-right: 10px;
    border: none;
    margin-bottom: 0px;
    color: #fff;
    padding: 10px;

    :focus {
      box-shadow: none;
    }
  }

  .react-tabs__tab--selected {
    background: transparent;
    border: none;
    border-radius: 0px;
    color: #fff;
    border-bottom: 4px solid #c5a47e;
  }
`

export const PropertiesOuterWrapper = styled.div``

export const PropertiesLayout = styled.div``

export const SpecDimLayoutHeading = styled(Commonh6)`
  text-transform: uppercase;
  text-align: left;
`

export const SpecLayout = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const SpecIcon = styled.img`
  width: 25px;
  height: 25px;
`

export const SpecText = styled(Commonpara)`
  margin-bottom: 0px;
  margin-left: 10px;
  line-height: 1;
`

export const SliderWrapper = styled.div`
  position: relative;

  :before {
    content: "";
    background-image: url(${BackgroundImg});
    position: absolute;
    left: -20px;
    bottom: -50px;
    width: 103px;
    height: 400px;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
`

export const SliderLayout = styled.div`
  .propertyImg {
    height: 450px;

    @media ${device.laptop} {
      margin-bottom: 40px;
    }

    @media ${device.mobileXL} {
      height: 350px;
    }
  }
`

export const ImgButtonLeft = styled.button`
  position: absolute;
  line-height: 0px;
  border: none;
  background: none;
  outline: none;
  top: calc(50% - 25px);
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  z-index: 99;

  :hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
  }
  :focus {
    outline: 0;
  }
`

export const LeftIcon = styled(ChevronLeft)`
  width: 40px;
  height: 40px;
  color: #c5a47e;
`

export const ImgButtonRight = styled.button`
  position: absolute;
  line-height: 0px;
  border: none;
  background: none;
  outline: none;
  top: calc(50% - 25px);
  right: 0px;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;

  :hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
  }

  :focus {
    outline: 0;
  }
`

export const RightIcon = styled(ChevronRight)`
  width: 40px;
  height: 40px;
  color: #c5a47e;
`
