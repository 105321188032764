import styled from "styled-components"
import { device } from "../Common/device"
import { Navbar } from "react-bootstrap"

export const NavbarCustom = styled(Navbar)`
    background: ${props =>
      props.issticky ? "#ffffff" : "#ffffff00"} !important;
    padding:0px !important;

    @media ${device.laptop} {
        .navbar-collapse{
            background:#fff !important;
            padding:10px !important;
        }
    }
    @media ${device.mobileXL} {
        
        padding-left:15px !important;
        padding-right: ${props => (props.issticky ? "15px" : 0)} ;
    }

    //Scrollspy ul styles
    ul{
        margin:0;
    }

    li{
        padding:10px 20px;
        padding:${props => (props.issticky ? "10px 20px" : "15px 20px")} ;
        margin: 0;
        display: inline-flex;
    
        @media ${device.laptop}{
            display:block;
        }
    }

    //Anchor Link - Selected Menu Item
    .is-current a{

        //If it is sticky menu, then just change the menu color or else
        //2 px bottom border is required
        ${props =>
          props.issticky &&
          `
            color:#c5a47e;
        `}
        ${props =>
          !props.issticky &&
          `
            border-bottom:2px solid #c5a47e;
        `}

        @media ${device.laptop} {
            border:none;
            color:#c5a47e;
        }   
    }

    //Anchor Link Styles
    a{
        padding:0px 0px 10px;
        color: ${props => (props.issticky ? "#494949" : "#ffffff")};

        :hover{
            text-decoration:none;
            ${props =>
              props.issticky &&
              `
                color:#c5a47e;
            `}
            ${props =>
              !props.issticky &&
              `
                border-bottom:2px solid #c5a47e;
            `}
        }

        @media ${device.laptop} {
            :hover{
                border:none;
                color:#c5a47e;
            }
            
        } 

        @media ${device.laptop} {
            color:#494949;
        }
    }

    //Logo
    .navbar-brand {
        padding:0px;
        filter: contrast(0.7);

        @media ${device.laptop} {
            padding:15px;
        }

        :hover{
            border-bottom:0px;
        }
    }

    .contactBtn {
        background: #c5a47e;
        color: #fff;
        padding: 8px 30px !important;
        text-decoration:none;
        cursor:pointer;

        :hover{
            background:#9e7647;
            border:none;
            color:#fff;
        }
    }
    
    .is-current .contactBtn{
        color:#fff;
    }
`

export const BrandLogo = styled.img`
  margin-bottom: 0px;
  max-width: ${props => (props.issticky ? "140px" : "175px")};
  transition: all 0.5s;

  @media ${device.mobileM} {
    max-width: 145px;
  }
`

export const NavbarToggleCustom = styled(Navbar.Toggle)`
    outline:0px !important;

    border: none;
    .navbar-toggler-icon{
    background-image:url("${props =>
      props.issticky
        ? "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(197, 164, 126)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"
        : "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(197, 164, 126)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"}") !important;
    }
`
