import React, { Component } from "react"
import Headermenu from "../containers/wineabroad/HeaderMenuBS"
import Banner from "../containers/wineabroad/Banner"
import About from "../containers/wineabroad/About"
import PropertyDetails from "../containers/wineabroad/PropertyDetails"
import Features from "../containers/wineabroad/Features"
import Footer from "../containers/wineabroad/Footer"

import GlobalStyle from "../containers/wineabroad/Common/global-styles"
import Fonts from "../containers/wineabroad/Common/fonts"

import "../components/layout.css"
import SEO from "../components/seo"

import { withTrans } from "../pagedata/i18n/withTrans"

class Wineabroad extends Component {
  render() {
    return (
      <div>
        {
          <div>
            <Fonts />
            <GlobalStyle />
            <main>
              <SEO
                title="Wine Abroad | Your Export Office"
                description="Wine Abroad est le bureau export que vous cherchez ! Grâce à notre expertise et expérience exportez vos vins vers de nouveaux horizons."
              />
              <Headermenu />
              <Banner />
              <About />
              <Features />
              <PropertyDetails />
            </main>
            <Footer />
          </div>
        }
      </div>
    )
  }
}

export default withTrans(Wineabroad)
