import React from "react"
import {
  AboutSection,
  AboutWrapper,
  CustomRow,
  ImageHolder,
  TextLayout,
  Heading,
  HeadingSpan,
  Description,
} from "./about.style"
import { Container, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import GatsImg from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { withTrans } from "../../../pagedata/i18n/withTrans"

const About = props => {
  const JSONData = useStaticQuery(graphql`
    query {
      localesJson {
        en {
          translation {
            About {
              HeadingSpan
              Heading
              Description1
              Description2
              Description3
              Img {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        fr {
          translation {
            About {
              HeadingSpan
              Heading
              Description1
              Description2
              Description3
              Img {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const AboutData =
    props.language === "en"
      ? JSONData.localesJson.en.translation.About
      : JSONData.localesJson.fr.translation.About

  return (
    <AboutSection id="activitiesSection">
      <Container>
        <AboutWrapper>
          <CustomRow>
            <Col lg={6}>
              <TextLayout>
                <Heading>
                  <HeadingSpan>{AboutData.HeadingSpan}</HeadingSpan>
                  {AboutData.Heading}
                </Heading>
                <Description>{AboutData.Description1}</Description>
                <Description>{AboutData.Description2}</Description>
                <Description>{AboutData.Description3}</Description>
              </TextLayout>
            </Col>
            <Col lg={6}>
              <ImageHolder>
                <GatsImg
                  fluid={AboutData.Img.childImageSharp.fluid}
                  alt=""
                  className="aboutImg"
                />
              </ImageHolder>
            </Col>
          </CustomRow>
        </AboutWrapper>
      </Container>
    </AboutSection>
  )
}

export default withTrans(About)
