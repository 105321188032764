import React from "react"
import {
  FeaturesSection,
  FeaturesSingle,
  FeaturesImg,
  Heading,
  FeaturesDesc,
} from "./features.style"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { useStaticQuery, graphql } from "gatsby"

import { withTrans } from "../../../pagedata/i18n/withTrans"

const Features = props => {
  const JSONData = useStaticQuery(graphql`
    query {
      localesJson {
        en {
          translation {
            Features {
              FeaturesImg
              FeaturesHeading
              FeaturesDesc
            }
          }
        }
        fr {
          translation {
            Features {
              FeaturesImg
              FeaturesHeading
              FeaturesDesc
            }
          }
        }
      }
    }
  `)

  const FeaturesData =
    props.language === "en"
      ? JSONData.localesJson.en.translation.Features
      : JSONData.localesJson.fr.translation.Features
  return (
    <FeaturesSection id="featuresSection">
      <Container>
        <Row className="d-flex justify-content-center">
          {FeaturesData.map((feature, i) => (
            <Col md={4} key={i + "_feature"}>
              <FeaturesSingle>
                <FeaturesImg src={feature.FeaturesImg} alt="" />
                <Heading>{feature.FeaturesHeading}</Heading>
                <FeaturesDesc>{feature.FeaturesDesc}</FeaturesDesc>
              </FeaturesSingle>
            </Col>
          ))}
        </Row>
      </Container>
    </FeaturesSection>
  )
}

export default withTrans(Features)
