import React, { Component } from "react"
import {
  NavbarCustom,
  NavbarToggleCustom,
  BrandLogo,
} from "./headermenubs.style"
import { Container, Nav, Navbar } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticQuery, graphql } from "gatsby"

import LanguageMenu from "../LanguageMenu"

import ScrollSpy from "react-scrollspy"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { withTrans } from "../../../pagedata/i18n/withTrans"

class Headermenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyClass: "top",
      navExpanded: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let stickyClass = "topSticky"
      let scrollPos = window.scrollY
      if (scrollPos < 100) {
        stickyClass = "top"
      }
      if (this.state.stickyClass !== stickyClass) {
        this.setState({ stickyClass })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll")
  }

  setNavExpanded = expanded => {
    this.setState({ navExpanded: expanded })
  }

  setNavClose = () => {
    this.setState({ navExpanded: false })
  }

  render() {
    const HeaderData =
      this.props.language === "en"
        ? this.props.HeaderData.en.translation.Header
        : this.props.HeaderData.fr.translation.Header
    return (
      <NavbarCustom
        expand="lg"
        fixed="top"
        issticky={this.state.stickyClass === "topSticky" ? 1 : undefined}
        onToggle={this.setNavExpanded}
        expanded={this.state.navExpanded}
      >
        <Container className="d-flex justify-content-end mt-1 mb-1">
          {this.state.stickyClass === "topSticky" && (
            <Navbar.Brand href="/" className="m-auto">
              <BrandLogo
                src={HeaderData.BrandLogoSticky}
                issticky={
                  this.state.stickyClass === "topSticky" ? 1 : undefined
                }
                alt="Logo"
                className="ml-3"
              />
            </Navbar.Brand>
          )}

          <NavbarToggleCustom
            issticky={this.state.stickyClass === "topSticky" ? 1 : undefined}
            aria-controls="basic-navbar-nav"
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className={
                this.state.stickyClass === "topSticky" ? "ml-auto" : "m-auto"
              }
              onClick={this.setNavClose}
            >
              <ScrollSpy
                offset={-59}
                items={HeaderData.Items}
                currentClassName="is-current"
              >
                {HeaderData.MenuItems.map((menuItem, idx) => {
                  return (
                    <li key={idx + "_" + menuItem.href}>
                      <AnchorLink
                        offset={55}
                        href={menuItem.Href}
                        issticky={
                          this.state.stickyClass === "topSticky" ? 1 : undefined
                        }
                      >
                        {menuItem.Menu}
                      </AnchorLink>
                    </li>
                  )
                })}

                <li>
                  <AnchorLink
                    className="contactBtn"
                    offset={55}
                    href={HeaderData.ContactBtn.Href}
                  >
                    {HeaderData.ContactBtn.Menu}
                  </AnchorLink>
                </li>
                <li>
                  <LanguageMenu
                    issticky={
                      this.state.stickyClass === "topSticky" ? 1 : undefined
                    }
                  />
                </li>
              </ScrollSpy>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </NavbarCustom>
    )
  }
}

const HeaderMenuComponent = props => (
  <StaticQuery
    query={graphql`
      query {
        localesJson {
          en {
            translation {
              Header {
                BrandLogo
                BrandLogoSticky
                Items
                MenuItems {
                  Menu
                  Href
                }
                ContactBtn {
                  Menu
                  Href
                }
              }
            }
          }
          fr {
            translation {
              Header {
                BrandLogo
                BrandLogoSticky
                Items
                MenuItems {
                  Menu
                  Href
                }
                ContactBtn {
                  Menu
                  Href
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Headermenu HeaderData={data.localesJson} {...props} />}
  />
)

export default withTrans(HeaderMenuComponent)
