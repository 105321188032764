import styled from "styled-components"
import { device } from "../Common/device"
import "bootstrap/dist/css/bootstrap.min.css"

const white = "#fff"
const darkGrey = "#494949"
const beige = "#c5a47e"

export const LanguageMenuWrapper = styled.div``

export const LanguageMenuBtn = styled.button`
  font-size: 16px;
  background: none;

  color: ${props =>
    props.isActive && props.issticky
      ? beige
      : !props.isActive && props.issticky
      ? darkGrey
      : white};
  border: none;

  border-bottom: ${props =>
    props.isActive && !props.issticky ? `2px solid ${beige}` : "none"};

  &:hover {
    text-decoration: none;
    ${props =>
      props.issticky &&
      `
        color: ${beige};
    `}
    ${props =>
      !props.issticky &&
      `
        border-bottom:2px solid ${beige};
    `}
  }

  &:focus {
    outline: none !important;
  }

  @media ${device.laptop} {
    :hover {
      border: none;
      color: ${beige};
    }
  }

  @media ${device.laptop} {
    color: ${props => (props.isActive ? beige : darkGrey)};
    border: none;
  }
`

export const LanguageMenuSeparator = styled.span`
  color: ${props => (props.issticky ? "#494949" : "#ffffff")};
  @media ${device.laptop} {
    color: #494949;
  }
`
