import React, { Component } from "react"
import {
  PropertiesSection,
  CustomRow,
  Heading,
  HeadingSpan,
  PropertiesOuterWrapper,
  PropertiesLayout,
  Description,
  SpecDimLayoutHeading,
  SpecLayout,
  SpecText,
  SliderWrapper,
  SliderLayout,
} from "./propertydetails.style"
import { Container, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { Tabs, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import GatsImg from "gatsby-image"

import { withTrans } from "../../../pagedata/i18n/withTrans"

class Properties extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      tabIndex: 0,
    }
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    }

    const PropertiesData =
      this.props.language === "en"
        ? this.props.PropertiesData.en.translation.Properties
        : this.props.PropertiesData.fr.translation.Properties

    return (
      <PropertiesSection id="sourcingSection">
        <Container>
          <Tabs
            selectedIndex={this.state.tabIndex}
            onSelect={tabIndex => this.setState({ tabIndex })}
          >
            <CustomRow>
              <Col lg={5}>
                {PropertiesData.TabList.map((itemTab, idxTab) => {
                  return (
                    <TabPanel key={idxTab + "_k"}>
                      <SliderWrapper>
                        {/* <ImgButtonLeft
                          onClick={this.previous}
                          aria-label="Prev Button"
                        >
                          <LeftIcon />
                        </ImgButtonLeft> */}
                        <Slider ref={c => (this.slider = c)} {...settings}>
                          {itemTab.TabPanel.map((itemPanel, idxPanel) => {
                            return (
                              <SliderLayout key={idxPanel + "_i"}>
                                <GatsImg
                                  fluid={itemPanel.Img.childImageSharp.fluid}
                                  alt=""
                                  className="propertyImg"
                                />
                              </SliderLayout>
                            )
                          })}
                        </Slider>
                        {/* <ImgButtonRight
                          onClick={this.next}
                          aria-label="Next Button"
                        >
                          <RightIcon />
                        </ImgButtonRight> */}
                      </SliderWrapper>
                    </TabPanel>
                  )
                })}
              </Col>
              <Col lg={7}>
                <Heading>
                  {PropertiesData.Heading}
                  <HeadingSpan>{PropertiesData.HeadingSpan}</HeadingSpan>
                </Heading>

                {
                  <PropertiesOuterWrapper>
                    <PropertiesLayout>
                      <Description>
                        {PropertiesData.TabList[0].Description}
                      </Description>
                      <Row>
                        <Col xs={4} sm={4} md={4}>
                          <SpecDimLayoutHeading>
                            {PropertiesData.TabList[0].SpecLayoutHeadingOne}
                          </SpecDimLayoutHeading>
                          {PropertiesData.TabList[0].SpecFrenchStates.map(
                            (country, countryIdx) => (
                              <SpecLayout key={country + "_sp"}>
                                <SpecText>{country}</SpecText>
                              </SpecLayout>
                            )
                          )}
                        </Col>
                        <Col xs={4} sm={4} md={4}>
                          <SpecDimLayoutHeading>
                            {PropertiesData.TabList[0].SpecLayoutHeadingTwo}
                          </SpecDimLayoutHeading>
                          {PropertiesData.TabList[0].SpecSpanishStates.map(
                            (country, countryIdx) => (
                              <SpecLayout key={countryIdx + "_di"}>
                                <SpecText>{country}</SpecText>
                              </SpecLayout>
                            )
                          )}
                        </Col>
                        <Col xs={4} sm={4} md={4}>
                          <SpecDimLayoutHeading>
                            {PropertiesData.TabList[0].SpecLayoutHeadingThree}
                          </SpecDimLayoutHeading>
                          {PropertiesData.TabList[0].SpecOtherCountries.map(
                            (country, countryIdx) => (
                              <SpecLayout key={countryIdx + "_di"}>
                                <SpecText>{country}</SpecText>
                              </SpecLayout>
                            )
                          )}
                        </Col>
                      </Row>
                    </PropertiesLayout>
                  </PropertiesOuterWrapper>
                }
              </Col>
            </CustomRow>
          </Tabs>
        </Container>
      </PropertiesSection>
    )
  }
}

const PropertiesComponent = props => (
  <StaticQuery
    query={graphql`
      query {
        localesJson {
          en {
            translation {
              Properties {
                Heading
                HeadingSpan
                TabList {
                  Tab
                  Description
                  TabPanel {
                    Img {
                      childImageSharp {
                        fluid(quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  SpecLayoutHeadingOne
                  SpecLayoutHeadingTwo
                  SpecLayoutHeadingThree
                  SpecFrenchStates
                  SpecSpanishStates
                  SpecOtherCountries
                  SpecLayoutHeading
                }
              }
            }
          }
          fr {
            translation {
              Properties {
                Heading
                HeadingSpan
                TabList {
                  Tab
                  Description
                  TabPanel {
                    Img {
                      childImageSharp {
                        fluid(quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }

                  SpecLayoutHeadingOne
                  SpecLayoutHeadingTwo
                  SpecLayoutHeadingThree
                  SpecFrenchStates
                  SpecSpanishStates
                  SpecOtherCountries
                  SpecLayoutHeading
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Properties PropertiesData={data.localesJson} {...props} />}
  />
)

export default withTrans(PropertiesComponent)
