import React, { Component } from "react"
import {
  BannerSection,
  BannerWrapper,
  BannerSlide,
  BannerImageHolder,
  BannerContents,
  NextPrev,
  ImgButton,
  PrevImg,
  NextImg,
  Brand,
} from "./banner.style"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticQuery, graphql } from "gatsby"
import GatsImg from "gatsby-image"
import { Container } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { withTrans } from "../../../pagedata/i18n/withTrans"

class Banner extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const settings = {
      arrows: false,
      fade: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,

      appendDots: dots => (
        <div>
          <ul>{dots}</ul>
        </div>
      ),

      customPaging: i => (
        <div>
          <span></span>
        </div>
      ),
    }

    const BannerData =
      this.props.language === "en"
        ? this.props.BannerData.en.translation.Banner
        : this.props.BannerData.fr.translation.Banner
    return (
      <BannerSection id="homeSection">
        <BannerWrapper>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {BannerData.BannerSlides.map((item, idx) => {
              return (
                <BannerSlide key={idx + "_" + item.BannerHeading}>
                  <BannerImageHolder>
                    <GatsImg
                      fluid={item.BannerImage.childImageSharp.fluid}
                      alt=""
                      className="bannerImage"
                    />
                  </BannerImageHolder>

                  <BannerContents>
                    <Container className="d-flex justify-content-center">
                      <Brand />
                    </Container>
                  </BannerContents>
                </BannerSlide>
              )
            })}
          </Slider>
          <NextPrev>
            <ImgButton onClick={this.previous} aria-label="Prev Button">
              <PrevImg src={BannerData.PrevImg} alt="" />
            </ImgButton>
            <ImgButton onClick={this.next} aria-label="Next Button">
              <NextImg src={BannerData.NextImg} alt="" />
            </ImgButton>
          </NextPrev>
        </BannerWrapper>
      </BannerSection>
    )
  }
}

const BannerComponent = props => (
  <StaticQuery
    query={graphql`
      query {
        localesJson {
          en {
            translation {
              Banner {
                BannerSlides {
                  BannerImage {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  BannerHeading
                  BannerSubHeading
                }
              }
            }
          }
          fr {
            translation {
              Banner {
                BannerSlides {
                  BannerImage {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  BannerHeading
                  BannerSubHeading
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Banner BannerData={data.localesJson} {...props} />}
  />
)

export default withTrans(BannerComponent)
