import styled from "styled-components"
import { device } from "../Common/device"
import { Commonh2, Commonpara, Commonh5 } from "../Common/common.style"
import { Row } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

export const AboutSection = styled.section`
  padding: 0px 0px;
  background: #000;

  @media ${device.tablet} {
    padding: 0px 10px;
  }
`

export const AboutWrapper = styled.div`
  background: #151515;
  padding: 40px;
  margin-top: -80px;
  position: relative;

  @media ${device.tablet} {
    margin-top: -60px;
  }
`

export const CustomRow = styled(Row)`
  align-items: center;

  @media ${device.laptop} {
    flex-direction: column;
  }
`

export const ImageHolder = styled.div`
  @media ${device.laptop} {
    margin-top: 40px;
  }

  .aboutImg {
    height: 400px;

    @media ${device.mobileXL} {
      height: 200px;
    }
  }
`

export const TextLayout = styled.div``

export const Heading = styled(Commonh2)`
  margin-bottom: 30px;
`

export const HeadingSpan = styled.span`
  color: #c5a47e;
`

export const Description = styled(Commonpara)``

export const FeaturesLayout = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

export const FeaturesTextLayout = styled.div`
  margin-left: 10px;
`

export const FeaturesImg = styled.img`
  width: 50px;
  height: 50px;

  @media ${device.mobileM} {
    width: 40px;
    height: 40px;
  }
`

export const FeaturesHeading = styled(Commonh5)`
  line-height: 1;
  text-align: left;
  margin-bottom: 5px;
`

export const FeaturesDesc = styled(Commonpara)`
  text-align: left;
  line-height: 1;
  margin-bottom: 0px;
`
