import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const translations = require("../locales/index.json")

i18next.use(LanguageDetector).init({
  fallbackLng: "fr",
  resources: translations,
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

i18next.languages = ["fr", "en"]

export default i18next
